// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\stack\\greatharbour\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("C:\\stack\\greatharbour\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("C:\\stack\\greatharbour\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("C:\\stack\\greatharbour\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("C:\\stack\\greatharbour\\src\\pages\\privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("C:\\stack\\greatharbour\\src\\pages\\terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\stack\\greatharbour\\.cache\\data.json")

